import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import "./appHeader.css";

export default function AppHeader() {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  return (
    <div className="header container">
      <div>
        <div className="title">{title}</div>
        <div className="sub-title">{description}</div>
      </div>

      <div className="navigation">
        <div className="navigation__links">
          <span>
            <Link to="/" activeStyle={{ opacity: 1 }}>
              BILLBOARD
            </Link>
          </span>
          <span>
            <Link to="/checkout" activeStyle={{ opacity: 1 }}>
              BUY PIXELS{" "}
              <span role="img" aria-label="shopping cart">
                🛍️
              </span>
            </Link>
          </span>
          <span>
            <Link to="/blog" activeStyle={{ opacity: 1 }}>
              BLOG{" "}
              <span role="img" aria-label="blog">
                📜
              </span>
            </Link>
          </span>
          <span>
            <a
              href="https://twitter.com/losagon_"
              target="_blank"
              rel="noreferrer"
            >
              CONTACT
            </a>
          </span>
          <span>
            <Link to="/faq" activeStyle={{ opacity: 1 }}>
              FAQ{" "}
              <span role="img" aria-label="faq">
                🙋
              </span>
            </Link>
          </span>
        </div>
        <pre className="sub-title">
          1,000,000 pixels • 1€ per pixel • 830,420 pixels left
        </pre>
      </div>
    </div>
  );
}
