import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import "./appFooter.css";

export default function AppFooter() {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  return (
    <footer className="container">
      <div className="sub-title">
        {`${title} © ${new Date().getFullYear()}. All rights reserved. | ${description}`}
      </div>
    </footer>
  );
}
