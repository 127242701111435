import React from "react";

import AppHeader from "./appHeader";
import AppFooter from "./appFooter";

import "./layout.css";

export default function Layout({ children }) {
  return (
    <div className="application">
      <div>
        <AppHeader />
        <main>{children}</main>
      </div>

      <AppFooter />
    </div>
  );
}
